import api from "./api";

export default {
    api: 'logs',
    /**
     * Get Logs
     * @returns {Promise}
     */
    getLogs(start, limit) {
        return new Promise((resolve, reject) => {
            api().get(this.api, {start: start, limit: limit}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    }
};
