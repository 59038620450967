<template>
    <div>
        <b-row>
            <b-col></b-col>
            <b-col sm="4" md="5" xl="4" class="my-1">
                <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('generally.tables.tipToSearch')"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    stacked="md"
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    show-empty
                    striped
                    selectable
                    select-mode="single"
                    ref="poolTable"
                    class="bg-white"
                    sort-by="timestamp"
                >
                    
                    <template #cell(timestamp)="row">
                        {{ $moment(row.item.createdAt).format('DD.MM.YYYY HH:mm:ss') }}
                    </template>

                    <template #cell(level)="row">
                        <img :src="row.item.level" v-show="row.item.imageLoad" width="20" height="20" @load="row.item.imageLoad = true" @error="row.item.imageLoad = false">
                        <b-icon-link45deg v-show="!row.item.imageLoad" class="text-orange"/>
                        {{ row.item.level }}
                    </template>
                    
                    <template #cell(ref)="row">
                        {{ row.item.ref }}
                    </template>

                    <template #cell(message)="row">
                         {{ row.item.message }}
                    </template>
        
                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import logService from "../../../services/logService";
import {EventBus} from "../../../services/EventBus";
import mainServices from "../../../services/mainServices";

export default {
    name: "LogsList",
    data: () => ({
        fields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectAll: false,
        selectedItems: [],
        items: [],
        eventBus: null,
        locale: '',
        images: {}
    }),
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.fields = [
            {key: 'timestamp', label: this.$t('pages.admin.logs.table.timestamp'), sortable: false, sortDirection: 'des'},
            {key: 'level', label: this.$t('pages.admin.logs.table.level'), sortable: false},
            {key: 'ref', label: this.$t('pages.admin.logs.table.ref'), sortable: false},
            {key: 'message', label: this.$t('pages.admin.logs.table.message'), sortable: false}
        ]
        this.load();
        this.locale = mainServices.getLocale();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.load();
        });

    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        load() {
            logService.getLogs().then(response => {
                this.items = response.data;
                this.totalRows = this.items.length;
            }).catch(() => {
                this.items = [];
                this.totalRows = this.items.length;
            });
        }
    }
};
</script>

<style scoped>

</style>
